let _profile = null;

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        accountRoles() {
          return _profile && _profile.roles ? _profile.roles : [];
        },
        profilePerson() {
          return _profile && _profile.person ? _profile.person : {};
        },
        isAdmin() {
          return true;
        },
        isIntern() {
          return false;
        },
      },
      methods: {
        hasRole(roles) {
          if (Array.isArray(roles)) {
            for (let i = 0; i < roles.length; ++i) {
              if (this.accountRoles.includes(roles[i])) return true;
            }
            return false;
          }
          return this.accountRoles.includes(roles);
        },
        async loadProfile() {
          _profile = await this.apiList({ resource: "app/profile" });
        },
        isPerson(person) {
          return (
            _profile &&
            _profile.person &&
            person &&
            _profile.person.id === person.id
          );
        },
      },
    });
  },
};
