<template>
  <v-app>
    <v-navigation-drawer
      dark
      permanent
      :mini-variant="!$vuetify.breakpoint.mdAndUp || miniVariant"
      app
      v-if="authenticated && !loading"
    >
      <v-list>
        <v-list-item
          v-if="!$vuetify.breakpoint.mdAndUp || miniVariant"
          @click.prevent="miniVariant = false"
        >
          <v-list-item-icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item v-else to="/">
          <v-list-item-avatar style="border-radius: 0">
            <v-img alt="GK Logo" contain :src="require('./assets/logo.svg')" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle
              >{{ profilePerson.code }}@{{ server }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.prevent="miniVariant = true"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <AppMenu :menu="menu"></AppMenu>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-list-item v-if="develop">
          <v-list-item-action>
            <v-switch inset v-model="local"></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ apiMode }}</v-list-item-title>
            <v-list-item-subtitle>API von {{ server }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="pa-2">
          <v-btn block @click="logout"> Logout </v-btn>
        </div>
        <div v-else class="pa-2">
          <v-btn icon @click="logout"><v-icon>mdi-logout</v-icon></v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-system-bar app v-if="!apiIsProd()" color="red" window>
        <v-icon>mdi-bug</v-icon>
        <strong>Du befindest dich auf admin2, unserer Test-Plattform!</strong>
        <v-spacer />
        Zugriff auf die echten Daten erfolgt über
        <v-btn text small href="https://admin.gymkirchenfeld.ch"
          >admin.gymkirchenfeld.ch</v-btn
        >
      </v-system-bar>
      <router-view v-if="authenticated && !loading"></router-view>
      <v-container
        v-if="!authenticated && !loading"
        class="fill-height d-flex align-center justify-center"
      >
        <v-alert type="error" border="left" prominent class="flex-grow-1"
          ><v-row
            ><v-col
              ><strong>403 forbidden</strong><br />Du hast keinen
              Zugriff!</v-col
            >
            <v-col
              ><v-list-item v-if="develop">
                <v-list-item-action>
                  <v-switch inset v-model="local"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ apiMode }}</v-list-item-title>
                  <v-list-item-subtitle
                    >API von {{ server }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-alert>
      </v-container>
      <v-container
        v-if="loading"
        class="fill-height d-flex align-center justify-center"
      >
        <v-row align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Verbinde mit Server
          </v-col>
          <v-col cols="4">
            <v-progress-linear indeterminate rounded></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>

      <ConfirmDialog ref="confirm" />
      <Notifications ref="notifications" />
      <PromptDialog ref="prompt" />
    </v-main>
  </v-app>
</template>

<script>
import menu from '@/menu';
import AppMenu from '@/components/AppMenu.vue';
import ConfirmDialog from '@/components/app/ConfirmDialog.vue';
import Notifications from '@/components/app/Notifications.vue';
import PromptDialog from '@/components/app/PromptDialog.vue';

export default {
  name: 'App',
  components: { AppMenu, ConfirmDialog, Notifications, PromptDialog },
  data() {
    return {
      authenticated: false,
      loading: true,
      server: '',
      local: false,
      items: [],
      menu: [],
      miniVariant: false,
    };
  },
  computed: {
    apiMode() {
      return this.local ? 'Entwicklung' : 'Test';
    },
    develop() {
      return process.env.NODE_ENV === 'development';
    },
  },
  watch: {
    local() {
      if (this.local) {
        this.apiSetDev();
      } else {
        this.apiSetTest();
      }
      this.updateBackend();
    },
  },
  methods: {
    logout() {
      this.$auth.logout();
    },
    updateBackend() {
      this.local = this.apiIsDev();
      this.server = this.apiServer();
    },
    async setAccount() {
      await this.loadProfile();
      this.authenticated =
        this.hasRole('sysAdmin') || this.hasRole('schoolAdmin');
      this.production = this.apiIsProd();
      this.updateBackend();
      this.menu = menu;
      this.loading = false;
    },
    search(event) {
      if (
        event &&
        event.target == document.body &&
        event.key &&
        event.key === '/'
      ) {
        const search = document.getElementById('search');
        if (search) {
          event.preventDefault();
          search.focus();
          search.select();
        }
      }
    },
  },
  async mounted() {
    this.loading = true;
    this.$root.confirm = this.$refs.confirm.open;
    this.$root.prompt = this.$refs.prompt.open;
    this.$root.showNotification = this.$refs.notifications.show;
    this.$root.showError = this.$refs.notifications.showError;
    this.$root.showInfo = this.$refs.notifications.showInfo;
    this.$root.showSuccess = this.$refs.notifications.showSuccess;
    window.addEventListener('keydown', this.search);
    const callback = {
      loggedIn: this.setAccount,
    };
    this.$auth.initialize(callback);
  },
};
</script>
