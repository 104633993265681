import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";

import vueapi from "@/plugins/vueapi.js";
import vueauth from "common/plugins/vueauth.js";
import vueprofile from "@/plugins/vueprofile.js";
import config from "./config.js";

Vue.config.productionTip = false;

Vue.use(vueapi);
Vue.use(vueauth);
Vue.use(vueprofile);

export const app = new Vue({
  vuetify,
  router,
  render(h) {
    return h(App);
  },
});
app.$auth.initConfiguration(config);
app.$mount("#app");
