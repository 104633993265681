<template>
  <v-list-item-action class="mr-4" v-on="{ ...$listeners }"
    ><slot v-bind="$attrs"></slot
  ></v-list-item-action>
</template>
<script>
export default {
  name: "ListIcon",
  props: {},
};
</script>
