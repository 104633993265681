export default [
  {
    title: "Allgemein",
    color: "info",
    visible: true,
    icon: "mdi-school",
    items: [
      {
        title: "Klassenübersicht",
        icon: "mdi-poll",
        link: "/schoolclassesoverview",
      },
      {
        title: "Wochenübersicht",
        icon: "mdi-calendar-week",
        link: "/weekoverview",
      },
      {
        title: "Klassen",
        icon: "mdi-account-group",
        link: "/schoolclasses",
      },
      {
        title: "Personen",
        icon: "mdi-account",
        link: "/people",
      },
      {
        title: "Kurse",
        icon: "mdi-human-male-board",
        link: "/courses",
      },
      {
        title: "Berichte",
        icon: "mdi-text-box-multiple",
        link: "/reports",
      },
      {
        title: "Aufgaben",
        icon: "mdi-checkbox-marked-outline",
        link: "/tasks",
      },
    ],
  },
  {
    title: "Schüler*innen",
    color: "error",
    visible: true,
    icon: "mdi-account-multiple",
    items: [
      {
        title: "Bes. rechtliche Lage",
        icon: "mdi-gavel",
        link: "/speciallegalstatus",
      },
      {
        title: "Dispensationen",
        icon: "mdi-account-remove",
        link: "/dispensations",
      },
      {
        title: "Maturaarbeiten",
        icon: "mdi-puzzle",
        link: "/thesis",
      },
      {
        title: "Portfolios",
        icon: "mdi-certificate",
        link: "/portfolios",
      },
    ],
  },
  {
    title: "Lehrer*innen",
    color: "error",
    visible: true,
    icon: "mdi-account-tie",
    items: [
      {
        title: "Abwesenheiten / Stv.",
        icon: "mdi-account-minus",
        link: "/teacher/absence",
      },
      {
        title: "Fachschaften",
        icon: "mdi-account-multiple",
        link: "/teacher/department",
      },
      {
        title: "Kürzel",
        icon: "mdi-account-settings",
        link: "/teacher/code",
      },
      {
        title: "Praktika",
        icon: "mdi-account-plus",
        link: "/teacher/stage",
      },
      {
        title: "temporäre Lehrer*innen",
        icon: "mdi-account-clock",
        link: "/teacher/temporary/",
      },
    ],
  },
  {
    title: "Planung",
    color: "success",
    visible: true,
    icon: "mdi-routes",
    items: [
      {
        title: "Stundenplan",
        icon: "mdi-clock-outline",
        link: "/timetable",
      },
      {
        title: "Sonderstundenplan",
        icon: "mdi-clock-edit",
        link: "/lessonChanges",
      },
      {
        title: "Terminliste",
        icon: "mdi-calendar",
        link: "/events",
      },
      {
        title: "Dokumente",
        icon: "mdi-file",
        link: "/documents",
      },
      {
        title: "myGymer",
        icon: "mdi-puzzle",
        link: "/optional",
      },
      {
        title: "onBoarding",
        icon: "mdi-airplane",
        link: "/onboarding",
      },
      {
        title: "Zuständigkeiten",
        icon: "mdi-account-question",
        link: "/index",
      },
    ],
  },
  {
    title: "Gebäude",
    color: "success",
    visible: true,
    icon: "mdi-home",
    items: [
      {
        title: "Schränke",
        icon: "mdi-locker-multiple",
        link: "/lockers/",
      },
      {
        title: "Screen",
        icon: "mdi-monitor",
        link: "/signage",
      },
      {
        title: "Raumreservation",
        icon: "mdi-door",
        link: "/roomreservation/",
      },
    ],
  },

  {
    title: "Finanzen",
    color: "success",
    visible: true,
    icon: "mdi-cash",
    items: [
      {
        title: "Rechnungen",
        icon: "mdi-cash-multiple",
        link: "/financeInvoices",
      },
      {
        title: "Anlässe",
        icon: "mdi-cash-clock",
        link: "/financeEvents",
      },
      {
        title: "Personenkonti",
        icon: "mdi-account-cash",
        link: "/financePerson",
      },
      {
        title: "Druckkonti",
        icon: "mdi-printer",
        link: "/financePrints",
      },
    ],
  },
  {
    title: "Informatik",
    color: "warning",
    visible: true,
    icon: "mdi-account-cowboy-hat",
    roles: ["sysAdmin"],
    items: [
      {
        title: "Accounts",
        icon: "mdi-card-account-details",
        link: "/accounts",
      },
      {
        title: "AD-Gruppen",
        icon: "mdi-vector-triangle",
        link: "/groups",
      },
      {
        title: "Initialpasswörter",
        icon: "mdi-key",
        link: "/initialpasswords",
      },
      {
        title: "Jobs",
        icon: "mdi-timer",
        link: "/jobs",
      },
      {
        title: "smartlearn",
        icon: "mdi-owl",
        link: "/smartlearn",
      },
      {
        title: "Änderungen",
        icon: "mdi-database-import",
        link: "/changes",
      },
      { title: "Inventar", icon: "mdi-tag-multiple", link: "/inventory" },
      { title: "Log", icon: "mdi-list-status", link: "/log" },
      { title: "Worklogs", icon: "mdi-calendar-text", link: "/worklogs" },
      { title: "Dokumentation", icon: "mdi-apps", link: "/documentation" },
    ],
  },
  {
    title: "Weiteres",
    color: "grey",
    icon: "mdi-dots-horizontal",
    visible: true,
    items: [
      {
        title: "Imitieren",
        roles: ["sysAdmin"],
        icon: "mdi-guy-fawkes-mask",
        link: "/impersonation",
      },
      {
        title: "Einstellungen",
        icon: "mdi-cogs",
        link: "/settings",
      },
    ],
  },
];
