let BASE_URL = "https://api.gymkirchenfeld.ch/api/";
if (process.env.NODE_ENV === "development") {
  if (localStorage.getItem("apiBase")) {
    BASE_URL = localStorage.getItem("apiBase");
  } else {
    BASE_URL = "https://api2.gymkirchenfeld.ch/api/";
  }
}

/* https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c */
function extractFileName(header) {
  if (!header) return "download.pdf";
  let contentDispostion = header.split(";");
  const fileNameToken = `filename=`;
  let fileName = "download.pdf";
  for (let thisValue of contentDispostion) {
    if (thisValue.trim().indexOf(fileNameToken) === 0) {
      fileName = decodeURIComponent(
        thisValue.trim().replace(fileNameToken, "")
      );
      break;
    }
  }

  return fileName;
}

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        apiServer() {
          return BASE_URL.split("//")[1].split(".")[0].split(":")[0];
        },
        apiBaseUrl() {
          return BASE_URL;
        },
        apiIsTest() {
          return BASE_URL === "https://api2.gymkirchenfeld.ch/api/";
        },
        apiIsDev() {
          return BASE_URL === "http://localhost:9000/api/";
        },
        apiIsProd() {
          return BASE_URL === "https://api.gymkirchenfeld.ch/api/";
        },
        apiSetTest() {
          BASE_URL = "https://api2.gymkirchenfeld.ch/api/";
          localStorage.setItem("apiBase", BASE_URL);
        },
        async apiPortraitImage(id, large, date) {
          const options = await this._createOptions("GET");
          try {
            let url =
              BASE_URL +
              "person/portrait/" +
              id +
              "?small" +
              (date ? "&date=" + date : "");
            if (large) {
              url =
                BASE_URL +
                "person/portrait/" +
                id +
                (date ? "?date=" + date : "");
            }
            const response = await fetch(url, options);
            if (!response.ok) return null;
            const blob = await response.blob();
            if (blob.size === 0) return null;
            const blobUrl = URL.createObjectURL(blob);
            return blobUrl;
          } catch (err) {
            console.log(err);
          }
        },
        apiSetDev() {
          BASE_URL = "http://localhost:9000/api/";
          localStorage.setItem("apiBase", BASE_URL);
        },
        async apiBlob(params) {
          const headers = new Headers();
          const accessToken = await this.$auth.getToken();
          if (!accessToken) {
            return params.defaultValue;
          }

          headers.append("Authorization", `Bearer ${accessToken}`);
          const options = {
            //cache: 'no-cache',
            headers: headers,
            method: "GET",
            signal: params.signal,
          };
          const url = `${BASE_URL}${params.path}`;
          try {
            const response = await fetch(url, options);
            if (!response.ok) {
              return params.defaultValue;
            }

            if (response.status === 204) return;
            const blob = await response.blob();
            if (blob.size === 0) return null;
            const blobUrl = URL.createObjectURL(blob);
            return blobUrl;
          } catch (error) {
            console.error(error);
            return params.defaultValue;
          }
        },
        async apiDownload(params) {
          const options = await this._createOptions("GET");
          const url = `${BASE_URL}${params.resource}/${params.id}`;
          const response = await fetch(url, options);
          if (response.status !== 200) {
            console.log(options);
            return;
          }
          let link = document.createElement("a");
          link.download = extractFileName(
            response.headers.get("content-disposition")
          );
          link.href = window.URL.createObjectURL(await response.blob());
          link.click();
        },
        async apiDelete(params) {
          let path = params.resource;
          if (params.id) path += `/${params.id}`;
          if (params.query) path += `?${params.query}`;
          return await this.apiRequest({
            method: "DELETE",
            path: path,
            onError: params.onError,
            onSuccess: params.onSuccess,
          });
        },
        async apiGet(params) {
          let path = `${params.resource}/${params.id}`;
          if (params.query) path += `?${params.query}`;
          return await this.apiRequest({
            method: "GET",
            path: path,
          });
        },
        async apiList(params) {
          let path = params.resource;
          if (params.query) path += `?${params.query}`;
          return await this.apiRequest({
            method: "GET",
            path: path,
            defaultValue: params.defaultValue || [],
          });
        },
        async apiPatch(params) {
          const path = `${params.resource}/${params.id}`;
          const body = {};
          body[params.key] = params.value;
          return await this.apiRequest({
            method: "PATCH",
            path: path,
            body: body,
            onError: params.onError,
            onSuccess: params.onSuccess,
          });
        },
        async apiPost(params) {
          return await this.apiRequest({
            method: "POST",
            path: params.resource,
            body: params.data,
            onError: params.onError,
            onSuccess: params.onSuccess,
          });
        },
        async apiPut(params) {
          let path = params.resource;
          if (params.data.id) path += `/${params.data.id}`;
          return await this.apiRequest({
            method: "PUT",
            path: path,
            body: params.data,
            onError: params.onError,
            onSuccess: params.onSuccess,
          });
        },
        async apiSave(params) {
          return params.create
            ? await this.apiPost(params)
            : await this.apiPut(params);
        },
        async apiUploadPost(params) {
          const path = `${params.resource}`;
          return await this.apiRequest({
            method: "POST",
            path: path,
            noJson: true,
            body: new FormData(params.form),
          });
        },
        async apiUploadPut(params) {
          const path = `${params.resource}/${params.data.id}`;
          const formData = new FormData();
          formData.append("file", params.file, params.fileName);
          return await this.apiRequest({
            method: "PUT",
            path: path,
            noJson: true,
            body: formData,
          });
        },
        async _waitForReport(id, params) {
          const options = await this._createOptions("GET");
          const url = `${BASE_URL}app/reportjob/${id}`;
          const response = await fetch(url, options);
          if (response.status !== 200) {
            params.onError();
            return;
          }
          const contentType = response.headers.get("content-type");
          if (contentType === "application/json") {
            const json = await response.json();
            params.onProgress(json.result.progress);
            setTimeout(this._waitForReport, 100, id, params);
          } else {
            const blob = await response.blob();
            const fileName = extractFileName(
              response.headers.get("content-disposition")
            );
            const result = {
              blob: blob,
              fileName: fileName,
              download() {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(this.blob);
                link.download = this.fileName;
                link.click();
              },
              open() {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(this.blob);
                link.click();
              },
              openInNew() {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(this.blob);
                link.target = "_blank";
                link.click();
              },
            };
            params.onDownload(result);
          }
        },
        async apiReport(params) {
          if (params.openInNew) {
            params.onDownload =
              params.onDownload ||
              function (file) {
                file.openInNew();
              };
          } else if (params.download) {
            params.onDownload =
              params.download ||
              function (file) {
                file.download();
              };
          } else {
            params.onDownload =
              params.onDownload ||
              function (file) {
                file.open();
              };
          }

          params.onError = params.onError || function () {};
          params.onProgress = params.onProgress || function () {};

          let url = `${params.resource}`;
          //if (params.id) url += `/${params.id}`;
          const response = await this.apiRequest({
            method: "POST",
            path: url,
            body: params.data,
          });
          if (response) {
            params.onProgress(response.progress);
            setTimeout(this._waitForReport, 100, response.id, params);
          }
          return response;
        },
        async apiRequest(params) {
          if (params.onError) {
            const theFunction = params.onError;
            params.onError = async (response) => {
              const msg = await response.text();
              theFunction(msg);
            };
          } else {
            params.onError = this.handleError;
          }
          params.onSuccess = params.onSuccess || function () {};
          const options = await this._createOptions(params.method);
          if (!options) return params.defaultValue;

          if (params.noJson) {
            options.body = params.body;
          } else {
            options.body = JSON.stringify(params.body);
            options.headers.append("Content-Type", "application/json");
          }

          const url = `${BASE_URL}${params.path}`;
          try {
            const response = await fetch(url, options);
            if (!response.ok) {
              params.onError(response);
              return params.defaultValue;
            }
            params.onSuccess();
            if (response.status === 204) return;
            const contentType = response.headers.get("content-type");
            if (!contentType) return;
            return (await response.json()).result;
          } catch (error) {
            console.error(error);
            //            this.$root.noConnection();
            return params.defaultValue;
          }
        },
        async _createOptions(method) {
          const headers = new Headers();
          const accessToken = await this.$auth.getToken();
          if (!accessToken) return;
          headers.append("Authorization", `Bearer ${accessToken}`);

          return {
            headers: headers,
            method: method,
          };
        },
        async handleError(response) {
          const message = await response.text();
          if (message) {
            this.$root.showError(
              `Fehler ${response.status}:<br><strong>${message}</strong>`
            );
          } else {
            this.$root.showError(`Fehler ${response.status}`);
          }
        },
      },
    });
  },
};
